import './App.css';
import Home from './componets/home/Home';
import Footer from './componets/Footer';
 
import LogIn from './componets/Login/LogIn';
import Navbar from './componets/Navbar';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Invest1 from './componets/invest/Invest1';
import Invest2 from './componets/invest/Invest2';
import Invest3 from './componets/invest/Invest3'; 
import About from './componets/About';
import Service from './componets/Service'; 
import Profile from './componets/Login/Profile';
import { PrivateRoute } from './componets/PrivateRoute';
import SumBoatFream from './componets/SumBoatFream';
import FAQ from './componets/FAQ';
import GostTrader from './componets/GostTrader';
import LeavelUp from './componets/LeavelUp';
 
 

function App() {
  return (
    <div className="App">
 
{/*  */}
<ScrollToTop/>
<Navbar/>
<Routes>
  <Route path='/' element={<Home/>}/>
  <Route path ='/log-in' element={<LogIn/>}/> 
  {/* <Route path ='/basic-invest' element={<Invest1/>}/>
  <Route path ='/intermediate-invest' element={<Invest2/>}/>
  <Route path ='/pro-invest' element={<Invest3/>}/> */}
  <Route path ='/basic-invest' element={<PrivateRoute><Invest1/></PrivateRoute>}/>
  <Route path ='/intermediate-invest' element={<PrivateRoute><Invest2/></PrivateRoute>}/>
  <Route path ='/pro-invest' element={<PrivateRoute><Invest3/></PrivateRoute>}/>
  <Route path ='/sum-bot' element={<PrivateRoute><SumBoatFream/></PrivateRoute>}/>
  <Route path ='/our-story' element={<About/>}/>
  <Route path ='/our-products' element={<Service/>}/>
  <Route path ='/faq' element={<FAQ/>}/>
  <Route path ='/ghost-trader' element={<GostTrader/>}/>
  <Route path ='/level-up' element={<LeavelUp/>}/>



  <Route path ='/profile' element={<PrivateRoute><Profile/></PrivateRoute>}/>

  

  






   

  <Route/>
  <Route/>
  <Route/>

</Routes>
   
 
     <Footer/>
   
    </div>
  );
}

export default App;
