import React, { useEffect, useState } from 'react'
import { GiStarShuriken } from 'react-icons/gi'
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const text = () => {
        let arr = []
        for (let i = 0; i < 10; i++) {
            arr.push(
                <p class='d-flex gap-5 fs-1  text-nowrap  text-black'>
                    <span>Let's Invest Together</span>
                    <span><GiStarShuriken /></span>
                </p>

            )
        }
        return arr;
    }
    return (
        <div>
            <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
                <div class='rounded-5 col-11 text-white  d-flex align-items-center text-start justify-content-center ' style={{ backgroundImage: `url(${require('../Images/invest2CrtBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100% ', height: '80%' }}>
                    <div class='col-11 m-auto'>
                        <div class=''>
                            <p class='extraBigTitle fweb ' data-aos="zoom-in">OUR STORY</p>
                            <p class='mfs-4 fwt  tJustify' data-aos="fade-up">Our founders have over 20 years of financial experience, serving over 100,000 clients from retail investors to high-net-worth individuals. They dedicated 50,000 hours to understanding clients' needs, risk appetites, and preferences, gaining invaluable insights into what investors truly want and need for better financial solutions.</p>

                        </div>

                    </div>
                </div>
            </div>

            <div style={{ overflow: 'hidden' }}>
                <div class='d-flex gap-4 my-3' style={{ transform: `translateX(-${scrollPosition}px)` }}>{text()}</div>
            </div>


            {/* <div class='aboutSticky text-white mt-5'>
                <div class='aboutSticky1 col-11 m-auto rounded-5 d-flex align-items-center justify-content-center'>
                    <div   >
                        <p class='mfs-8 fweb'>VPS Server</p>
                        <p class='fs-5 fwt'>Our Top Trader</p>
                    </div>
                </div>
                <div class='aboutSticky2 col-11 m-auto rounded-5 d-flex align-items-center justify-content-center'>
                    <div  >
                        <p class='mfs-8 fweb'>VPS Server</p>
                        <p class='fs-5 fwt'>Our Top Trader</p>
                    </div>
                </div>
                <div class='aboutSticky3 col-11 m-auto rounded-5 d-flex align-items-center justify-content-center'>
                    <div   >
                        <p class='mfs-8 fweb'>VPS Server</p>
                        <p class='fs-5 fwt'>Our Top Trader</p>
                    </div>
                </div>
                <div class='aboutSticky4 col-11 m-auto rounded-5 d-flex align-items-start justify-content-center'>
                    <div class='col-11 text-start mt-5' >
                        <p class='mfs-7 fweb'>Let’s Invest Together</p>
                        <p class='fwt my-2 tJustify fs-5'>Simultaneously, they invested a similar amount of time with senior managers of numerous international banking and brokerage institutions. This allowed them to understand the good, the bad, and the ugly sides of the business, equipping them with the knowledge to create something better-something that truly serves the needs of everyday traders.</p>
                    </div>
                </div>
            </div> */}

            <div class='col-11 row m-auto my-5'>
                <div class='col-md-8'>
                    <div class='text-start'>
                        {/* <p class='mfs-7 fweb' data-aos="fade-up">Our Vision</p> */}
                        <p class='mfs-7 fweb' data-aos="fade-up"> Intuitive. </p>
                        <p class='mfs-7 fweb' data-aos="fade-up">Accessible. </p>
                        <p class='mfs-7 fweb' data-aos="fade-up"> Empowering.</p>
                        {/* <p class='mfs-7 fweb' data-aos="fade-up">Accessible. Intuitive. Empowering.</p> */}


                        {/* <p class='mfs-3 fwel my-2 tJustify' data-aos="fade-up">But our vision doesn't stop there. We believe in creating a community and empowering the next generation of investors, which is why 3Sum also enables you to involve your young family members in our learning campaigns and in person meets. As you progress on your journey of wealth management, you can be rest assured knowing that you're helping to equip the next generation with the knowledge and skills they need to succeed.</p>
                        <p class='mfs-3 fwel tJustify' data-aos="fade-up">3Sum was born from a deep understanding of the financial markets and a commitment to making those markets accessible to everyone. It's not just a platform; it's a partner in your financial journey-YOU, US, and the Markets-together, creating a future where smart investing is within everyone's reach.</p> */}
                        <p class='mfs-3 fwel my-2 tJustify' data-aos="fade-up"> 3Sum is a broker-agnostic platform designed to adapt as you grow. By forming strategic partnerships with reputable, well-regulated institutions, we offer state-of-the-art tools tailored to your trading journey. Whether you're just starting or looking for advanced services, 3Sum evolves with you, ensuring your trading environment meets your needs at every stage.</p>

                        <p class='mfs-3 fwel my-2 tJustify' data-aos="fade-up">Together, we create a future where YOU, US, and the Markets come together to make smart investing accessible to all. </p>


                    </div>
                </div>
                <div class='col-md-4'>
                    <img src={require('../Images/aboutVision.png')} data-aos="fade-up" style={{ width: '100%' }} alt="" />
                </div>

            </div>

            <div class='col-11 m-auto'>
                <p class='mfs-7' data-aos="fade-up"> You are Never Alone at</p>
                <div class='col-lg-6 col-11 m-auto my-4' data-aos="zoom-in">
                    <img src={require('../Images/about3sum.png')} style={{ width: '100%' }} alt="" />
                </div>
            </div>
            <br />
        </div>
    )
}

export default About