import axios from 'axios';
import React, { useEffect } from 'react' 
const Frame = () => {
  const embedKey = '386c786dfc954399ac56c18064af14a8';
  let token = localStorage.getItem("sum3UserAccessFinChatToken");

  useEffect(() => {
    function handleIframeReady(event) {
      if (event.data !== 'READY') return; 
      const targetWindow = window.frames['finchat']; 
      if (!targetWindow) return;
      targetWindow.postMessage({ token }, 'https://enterprise.finchat.io');
    } 
    window.addEventListener('message', handleIframeReady); 
    return () => window.removeEventListener('message', handleIframeReady);
  }, [token]); 
  return (
    <iframe
      name="finchat"
      src={`https://enterprise.finchat.io/${embedKey}`}
      style={{ border: 'none', width: '100%', height: '80vh' }}
      class='rounded-5 '
    />
  );
}; 
export default Frame;