import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GiStarShuriken } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { API } from './url';
import Swal from 'sweetalert2';
import { Link } from 'react-scroll';
import { FaArrowRightLong } from 'react-icons/fa6';



// const products= [
//         {
//             "id": "0ac9e2cc-8140-44dc-ad79-be1e67ea1e8f",
//             "title": "Quantum Global Ltd",
//             "subtitle": "Leading the Future of Algorithmic Trading",
//             "description": "<h2><strong>Overview</strong></h2><p>Quantum Global is a dynamic fintech company revolutionizing algorithmic trading and portfolio management since 2008. With relentless research and innovative technology, we specialize in advanced trading algorithms and robust risk management, making us a trusted choice for investors looking to optimize their portfolios.</p><p><br></p><h2><strong>Core Strengths and Achievements</strong></h2><ul><li>Proprietary Trading Systems: Our unique algorithms and automation tools identify and seize market opportunities with precision.</li><li>Quantitative Expertise: Backed by experts in statistical modeling and machine learning, we create resilient trading models.</li><li>Risk Management: A multi-layered risk approach ensures strong protection for client assets.</li><li>Proven Performance: Our consistent track record has positioned us as a reliable and forward-thinking player in financial markets.</li></ul><p><br></p><h2><strong>Core Values </strong></h2><ul><li>Innovation: We stay ahead of the curve, continually advancing in trading technology.</li><li>Transparency: Clear, straightforward information about strategies and results is at the heart of our client relationships.</li><li>Risk Management: Client asset protection is our priority, ensuring sustainable, long-term value.</li></ul>",
//             "image": "/uploads/image-1731760986788.png",
//             "link": "https://www.3sum.ai/",
//             "buttonText": "Coming Soon ",
//             "category": "Basic"
//         },
//         {
//             "id": "341229c6-8b05-4362-b06d-4e17d2db74a4",
//             "title": "FX Pro",
//             "subtitle": "FxPro empowers traders with Contracts for Difference (CFDs) across six major asset classes, including Forex, Shares, Spot Indices, Futures, Spot Metals, and Spot Energy. With FxPro, enjoy seamless access to premier liquidity and cutting-edge trade execution—without dealing desk intervention.",
//             "description": "<h1><strong>Key Features:</strong></h1><ul><li><strong>Wide Asset Range:</strong> Trade CFDs in Forex, Shares, Indices, Futures, Metals, and Energy.</li><li><strong>Top-Tier Liquidity:</strong> Benefit from superior liquidity for fast and reliable transactions.</li><li><strong>Advanced Execution:</strong> Experience efficient, no-intervention trading for precise order fulfillment.</li></ul>",
//             "image": "/uploads/image-1732055615385.png",
//             "link": "https://www.3sum.ai/",
//             "buttonText": "Coming Soon",
//             "category": "Basic"
//         },
//         {
//             "id": "5eb1d4ee-705f-4c32-b85f-1e96215e71f5",
//             "title": "Madhur Kakkar Consultation",
//             "subtitle": "I'm Madhur Kakkar, an investment strategist in Dubai, UAE, committed to helping you enhance your financial strategies and reach your investment goals.\r\n",
//             "description": "undefined",
//             "image": "/uploads/image-1732027187313.jpg",
//             "link": "https://madhurkakkar.com",
//             "buttonText": "Connect to Madhur",
//             "category": "Basic"
//         },
//         {
//             "id": "c1eae18c-9b46-4f79-afc1-73bc4ee6c084",
//             "title": "Quantl",
//             "subtitle": "Trade Smarter, Stress Less with AI-Driven Precision",
//             "description": "<p>QuantL AI is a state-of-the-art SaaS platform offering fully automated, AI-driven trading strategies. Using advanced algorithms and 25+ years of market data, we help traders at all levels achieve steady, emotion-free results through real-time trend analysis.</p><p><br></p><h1><strong>Our Mission</strong></h1><p>We aim to redefine trading by making it accessible, efficient, and emotion-neutral. QuantL AI delivers data-backed, automated strategies that optimize performance, minimize risk, and seize market opportunities around the clock—no monitoring required.</p><p><br></p><h1><strong>Our Vision</strong></h1><p>To be the top global platform for automated trading, trusted by traders worldwide. By advancing AI-driven solutions, we enable both beginners and experts to achieve success with smart, automated strategies.</p>",
//             "image": "/uploads/image-1732305211139.png",
//             "link": "https://www.3sum.ai/",
//             "buttonText": "Coming Soon",
//             "category": "Basic"
//         },
//         {
//             "id": "e242d53c-3d82-425c-a8b0-2220c1398e53",
//             "title": "Tracker Trader ",
//             "subtitle": "Achieve trading clarity with Trackatrader.",
//             "description": "<h1><strong>Features </strong></h1><p><strong>Make Informed Decisions:</strong> Analyze performance to refine your strategies.</p><p><strong>Spot Growth Opportunities:</strong> Uncover patterns to enhance your trading skills.</p><p><strong>Showcase Your Success:</strong> Share verified results confidently.</p><p><strong>Stay Goal-Focused:</strong> Keep yourself accountable and on track.</p>",
//             "image": "/uploads/image-1732380812231.png",
//             "link": "https://www.3sum.ai/",
//             "buttonText": "Coming Soon ",
//             "category": "Basic"
//         }
//     ]

const Service = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [details, setDetails] = useState()
    const [products, setProducts] = useState()

    const getProductDetails = async ( ) => {
        try {
          const response = await fetch(`${API}/products/service`, {
            method: 'get',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json', 
            },
          });
          const data = await response.json();  
          if (!response.ok) {
            throw new Error(data?.message);
          }
          setProducts(data?.payload?.products)
        } catch (error) {
          Swal.fire({
            text: error,
            icon: "error"
          });
        }
      };   

      console.log(products);

    const getPageDetails = async ( ) => {
        try {
          const response = await fetch(`${API}/content/service`, {
            method: 'get',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',  
            },
          });
          const data = await response.json();  
          if (!response.ok) {
            throw new Error(data?.message);
          }
          setDetails(data?.payload)
        } catch (error) {
          Swal.fire({
            text: error,
            icon: "error"
          });
        }
      };   
      useEffect(()=>{
        getProductDetails( )
        getPageDetails( ) 
      },[ ])


    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const text = () => {
        let arr = []
        for (let i = 0; i < 10; i++) {
            arr.push(
                <p class='d-flex gap-5 fs-1  text-nowrap  text-black'>
                    <span>Let's Invest Together</span>
                    <span><GiStarShuriken /></span>
                </p>

            )
        }
        return arr;
    }

    const navigate = useNavigate()
    return (
        <div>
            <div class='col-11 m-auto mt-lg-0 mt-5 row align-items-center serviceTop' >
                <div class='col-lg-7'>
                    <div class=' m-auto text-start mt-lg-0 mt-5'>
                        <p class='fwt display-4 '> <span  dangerouslySetInnerHTML={{ __html: details?.text1 }}></span> <span class='org fwsb'  dangerouslySetInnerHTML={{ __html: details?.text2 }}></span></p>
                        <p class='display-4'><span  dangerouslySetInnerHTML={{ __html: details?.text3 }}></span></p>
                        <p class='display-4 org fwsb'><span  dangerouslySetInnerHTML={{ __html: details?.text4 }}></span></p>

                        <p class='fwt tJustify'  dangerouslySetInnerHTML={{ __html: details?.description }}></p>
                        <button class='sumBtn mfs-4 rounded-5 mt-3'>Explore More</button> 
                    </div> 
           
                </div>
                <div class='col-lg-5'>
                    <div class='col-lg-11 col-md-7 m-auto  my-lg-0 my-4 position-relative' data-aos="zoom-in">
                        <img src={require('../Images/servicetop1.png')} class='rotation3' style={{ width: '100%', transition: '2s' }} alt="" />
                        <img src={require('../Images/servicetop2.png')} class='rotation2' style={{ width: '100%', transition: '1.5s', position: 'absolute', top: '0', left: '0' }} alt="" />
                        <img src={require('../Images/servicetop3.png')} class='rotation1' style={{ width: '100%', transition: '1s', position: 'absolute', top: '0', left: '0' }} alt="" />
                        <img src={require('../Images/servicetop4.png')} class='rotation4' style={{ width: '100%', position: 'absolute', top: '0', left: '0', transform: "rotate(0deg)" }} alt="" />
                        {/* <img src={require('../Images/servicetop4_2.png')} class='rotation3' style={{width:'100%',position:'absolute', top:'0',left:'0',transform:"rotate(60deg)"}} alt="" />
                        <img src={require('../Images/servicetop4_3.png')} class='rotation3' style={{width:'100%',position:'absolute', top:'0',left:'0',transform:"rotate(30deg)"}} alt="" />
                        <img src={require('../Images/servicetop4_4.png')} class='rotation3' style={{width:'100%',position:'absolute', top:'0',left:'0',transform:"rotate(60deg)"}} alt="" /> */}

                        <img src={require('../Images/servicetop5.png')} style={{ width: '100%', position: 'absolute', top: '0', left: '0' }} alt="" />


                    </div>
                </div>


            </div>

            <div style={{ overflow: 'hidden', background: 'rgba(255,130,60,0.2)' }}>
                <div class='d-flex gap-4 my-3' style={{ transform: `translateX(-${scrollPosition}px)` }}>{text()}</div>
            </div>

            <div class='col-11 m-auto mt-5'>
                <p class='fs-1'> Things That we're <span class='fwsb org'>Expert on</span></p>

                {/* {
                    ourProduct?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-black  align-items-center rounded-5 flex-column-reverse flex-lg-row p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', position: 'relative' }}>
                                <div class='col-xl-7 col-lg-6' style={{ zIndex: '1' }}>
                                    <div class='text-start col-11 m-auto my-3 text-black'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-5 fwt my-2'>{el?.des}</p>
                                        <div class='row'>
                                            {el?.bPoint?.map((e) => {
                                                return (
                                                    <p class='fwt mt-1 col-md-6 d-flex gap-2'>
                                                        <span class='org'><FaAngleDoubleRight /></span>
                                                        {e}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                        <button class='sumBtn mfs-4 rounded-5 mt-3'>Explore More</button>


                                    </div>
                                </div>
                                <div class='col-xl-5 col-lg-6  p-0 d-flex justify-content-center align-items-end' style={{ overflow: 'hidden', zIndex: '1' }}>
                                    <div class=' col-12'>
                                        <img style={{ width: '100%' }} src={el?.img} class='invest2cartImg' alt="" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                } */}

            </div>


{
    products  &&   
    <>
            <div class='col-11 m-auto   my-5' >
                <p class='mfs-8 fwb' data-aos="fade-up">Our Services</p>

                <div class='d-flex gap-3 flex-wrap  mt-5 '>
                    {  products?.map((el, index) => {
                        return (
                            <Link
                                activeClass="active"
                                to={`investD${index}`}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <button data-aos="fade-up" class='btn btn-outline-dark text-wrap rounded-5 fs-4 px-sm-4'>{el?.title}</button>
                            </Link>
                        )
                    })}
                </div>
            </div>

            <div class='col-11 m-auto'>
                { 
                    products?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-dark rounded-5 p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', position: 'relative' }}>
                             
                                <div class='col-lg-7' style={{ zIndex: '1' }}>
                                    <div class='text-start col-11 m-auto my-3  '>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-5 fwt mt-2'>{el?.subtitle}</p>
                                        <div  dangerouslySetInnerHTML={{ __html: el?.description }}></div> 
                                        <button class='sumBtn mfs-3 rounded-5 mt-3'  onClick={()=>window.open(el?.link, '_blank')}>{el?.buttonText}</button>
                                    </div>
                                </div>
                               
                                <div class='col-lg-5 rounded-5 p-0 d-flex align-items-center   ' style={{ overflow: 'hidden', position: 'relative', zIndex: '1', background:'#fff3ec' }}>
                                <div class='proImgeIcon' onClick={()=>window.open(el?.link, '_blank')}> <p class='fs-3 fw-bold text-white'><FaArrowRightLong /></p> </div>
                                    <div class='col-11 m-auto' style={{minHeight:'100px'}}> 
                                    <img class='rounded-4' src={`${API}`+`${el?.image}`} style={{ width: '100%'}} alt=""  onClick={()=>window.open(el?.link, '_blank')} />
                                    </div>
                                </div> 
                            </div>
                        )
                    })
                }
            </div>
    </>
}

            <br />
        </div>
    )
}

export default Service